import { useInstantSearch } from 'react-instantsearch';
import styles from './CoverSearchbox.module.scss';

export const SearchStatistics = () => {
  const { results } = useInstantSearch();

  return (
    <div className={styles.searchStatistics}>
      <span>
        {results.nbHits} results in {results.processingTimeMS}ms
      </span>
    </div>
  );
};
